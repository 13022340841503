//styles
import './City.css'
//images
import city1 from '../../assets/city/city1.svg'
import city2 from '../../assets/city/city2.svg'
import background from '../../assets/city/background.svg'

export default function City() {
  return (
    <div className='city background'>
      <img src={city2} id="background" alt="cityscape"/>
      <img src={city2} id="background2" alt="cityscape"/>
      <img src={city1} id="city1" alt="building"/>
      <img src={background} id="city2" alt="building"/>
    </div>
  )
}
