//styles
import './Forest.css'
//images
import tree1 from '../../assets/forest/tree1.svg'
import tree2 from '../../assets/forest/tree2.svg'
import hill1 from '../../assets/forest/hill1.svg'
import hill2 from '../../assets/forest/hill2.svg'

export default function Forest() {
  return (
    <div className='forest background'>
      <img src={tree1} id="treegroup1" alt="tree"/>
      <img src={tree2} id="treegroup2" alt="tree"/>
      <img src={hill1} id="hill1" alt="hill"/>
      <img src={hill2} id="hill2" alt="hill"/>
    </div>
  )
}
