import { FaGithub, FaLinkedin } from 'react-icons/fa'

//styles
import './Footer.css'

//images
import nameTag from '../../assets/nametag.svg'


export default function Footer({scrollToSection,references}) {

  return (
    <footer className='footer'>
        <div className='footer-socialmedia'>
            <a href='https://www.linkedin.com/in/masonswager' target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
            </a>
            <a href='https://github.com/mlswager' target="_blank" rel="noopener noreferrer">
                <FaGithub />
            </a>
        </div>
        <div className='footer-low'>
            <div className='footer-left'>
                <div>
                    <img src={nameTag} alt="Mason Swager logo"/>
                    <p>Mason Swager Web Developer</p>
                    <p className='footer-email'>email: mlswager2@gmail.com</p>
                </div>
            </div>
            <div className='footer-right'>
                <ul className='footer-nav'>
                    <li 
                        onClick={() => references.refLanding.current.scrollIntoView({behavior: 'smooth'})}
                    >To the Top</li>
                    <li 
                        onClick={() => references.refProjects.current.scrollIntoView({behavior: 'smooth'})}
                    >Projects</li>
                    <li 
                        onClick={() => references.refAbout.current.scrollIntoView({behavior: 'smooth'})}
                    >About</li>
                    <li 
                        onClick={() => references.refContact.current.scrollIntoView({behavior: 'smooth'})}
                    >Contact</li>
                </ul>
            </div>
        </div>
        
        


        
    </footer>
  )
}
