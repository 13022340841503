//styles
import './Landing.css'
//images
import nametag from '../../assets/nametag.svg'
import Mountains2 from './Mountains2'


export default function Landing({refLanding}) {
  return (
    <div className='landing container' ref={refLanding}>
      <div className='nametag'>
        <img src={nametag} alt='Mason Swager logo'className='nametag'/>
        <h1>Mason Swager Web Developer</h1>
      </div>
      <Mountains2 />
    </div>
  )
}
