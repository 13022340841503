//images
import herbalImage from '../../../assets/projects/herbal-image.png'

const herbalData = {
    name:"herbal",
    link:"https://mswager-herbal-demo.herokuapp.com/",
    github:"https://github.com/mlswager/herbal",
    notes:'due to changing policies with hosting service this project is undergoing updates',
    image:herbalImage,
    desc:"Herbal is a web application that I created upon completion of my first fullstack web development bootcamp. That bootcamp (by the Fullstack Academy in partnership with the University of Illinois Chicago) taught me a lot about web design and development, and I felt the need to put those skills to use right away by creating my first full stack web application.\n\nHerbal was created with PostgreSQL, Node, Express, and React - along with a good deal of css for styling!",
}

export default herbalData