import { FaGithub, FaLinkedin } from 'react-icons/fa'

//styles
import './Contact.css'

//components
import City from './City'

export default function Contact({refContact}) {
  return (
    <div id="cityscape" className='contact container' ref={refContact}>
      <div className='contact-info'>
        <p>email: mlswager2@gmail.com</p>
        <div className='contact-socialmedia'>
        <a href='https://www.linkedin.com/in/masonswager' target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
            </a>
            <a href='https://github.com/mlswager' target="_blank" rel="noopener noreferrer">
                <FaGithub />
            </a>
        </div>
      </div>
      <City/>
    </div>
  )
}
