import { useState, useRef, useEffect } from 'react';
//style
import './App.css';
//components
import Landing from './components/landing/Landing';
import Projects from './components/projects/Projects';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Menu from './components/menu/Menu';
import Footer from './components/footer/Footer';

function App() {
  const [active,setActive]=useState(null)
  const [modal,setModal] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const refLanding=useRef(null)
  const refProjects=useRef(null)
  const refAbout=useRef(null)
  const refContact=useRef(null)

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth))
  },[])

  //useEffect to determine where we are on the page and set the active menu element to that section
  // useEffect(() => {
  //   // console.log('refLanding', refLanding.current);
  //   const observer = new IntersectionObserver((entries, observer) => {
  //     entries.forEach((entry)=>{
  //       console.log(entry)
  //     })
  //     // const entry = entries[0]
  //     // console.log('entry: ',entry)
  //     // // console.log('entry.isIntersecting: ', entry)
  //     // return entry.isIntersecting
  //   })
  //   observer.observe(refLanding.current) && setActive('landing')

  // }, []);

  const scrollToSection = ( elementRef,activate ) => {
    console.log('clicked')
    window.scrollTo({
      top: elementRef.current.offsetTop -25,
      behavior: "smooth"
    })
    setActive(activate)
  }


  
  // observer.observe(document.querySelector('.my-element'));

  return (
    <div className={!modal ? "App" : "App modal-open"}>
      <Menu 
        references={{refLanding,refProjects,refAbout,refContact}} 
        scrollToSection={scrollToSection}
        active={active}
        width ={width}
      />
      <div className='content'>
        <Landing refLanding={refLanding}/>
        <Projects refProjects={refProjects} setModal={setModal}/>
        <About refAbout={refAbout}/>
        <Contact refContact={refContact}/>
      </div>
      <Footer 
        references={{refLanding,refProjects,refAbout,refContact}} 
        scrollToSection={scrollToSection}
      />
    </div>
  );
}

export default App;
