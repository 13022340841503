//images
import dojoImage from '../../../assets/projects/dojo-image.png'

const dojoData = {
    name:"the dojo",
    link:"https://thedojosite-f09f9.web.app",
    github:null,
    notes:null,
    image:dojoImage,
    desc:"the dojo is a project management web application built as part of the “Build Web Apps with React & Firebase” course on Udemy by Shaun Pelling. The project was originally built with Firebase 8, but I decided to take what I had learned throughout the class to optimize the dojo for Firebase 9. Feel free to signup and take a look around. There will be more future changes as I adapt the proect to my individual needs.",
}

export default dojoData