import { useState } from 'react'
import { FaBars } from 'react-icons/fa'
//style
import './Menu.css'

export default function Menu({references, scrollToSection, active, width}) {
    const [navStatus,setnavStatus]=useState(false)

    const toggleNav = () => {
        setnavStatus (prev => !prev)
    }

    const mobileSelect = (target) => {
        target.scrollIntoView({behavior: 'smooth'})
        setnavStatus(false)
    }

    if(width>780){
        return (
            <ul className='menu'>
                <li 
                    className={active==='landing' ? 'menu-item active' : 'menu-item'} 
                    onClick={() => references.refLanding.current.scrollIntoView({behavior: 'smooth'})}
                >To the Top</li>
                <li 
                    className={active==='projects' ? 'menu-item active' : 'menu-item'} 
                    onClick={() => references.refProjects.current.scrollIntoView({behavior: 'smooth'})}
                >Projects</li>
                <li 
                    className={active==='about' ? 'menu-item active' : 'menu-item'} 
                    onClick={() => references.refAbout.current.scrollIntoView({behavior: 'smooth'})}
                >About</li>
                <li 
                    className={active==='contact' ? 'menu-item active' : 'menu-item'} 
                    onClick={() => references.refContact.current.scrollIntoView({behavior: 'smooth'})}
                >Contact</li>
            </ul>
        )
    }
    return(
        <div id="buttonContainer">
            <button className={!navStatus ? 'menu-closed' : 'menu-open'} onClick={toggleNav}><FaBars /></button>
            {navStatus && <ul className='menu'>
                <li 
                    className={active==='landing' ? 'menu-item active' : 'menu-item'} 
                    onClick={()=>mobileSelect(references.refLanding.current)}
                    // onClick={() => references.refLanding.current.scrollIntoView({behavior: 'smooth'})}
                >To the Top</li>
                <li 
                    className={active==='projects' ? 'menu-item active' : 'menu-item'} 
                    onClick={()=>mobileSelect(references.refProjects.current)}
                    // onClick={() => references.refProjects.current.scrollIntoView({behavior: 'smooth'})}
                >Projects</li>
                <li 
                    className={active==='about' ? 'menu-item active' : 'menu-item'} 
                    onClick={()=>mobileSelect(references.refAbout.current)}
                    // onClick={() => references.refAbout.current.scrollIntoView({behavior: 'smooth'})}
                >About</li>
                <li 
                    className={active==='contact' ? 'menu-item active' : 'menu-item'} 
                    onClick={()=>mobileSelect(references.refContact.current)}
                    // onClick={() => references.refContact.current.scrollIntoView({behavior: 'smooth'})}
                >Contact</li>
            </ul>}
        </div>
    )
}
