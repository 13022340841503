//styles
import './About.css'

// images
import profile from '../../assets/mason_swager.jpg'

//components
import Forest from './Forest'


export default function About({refAbout}) {
  return (
    <div className='about container' ref={refAbout}>
      <div className='about-info'>
        <img src={profile} alt='Mason Swager'/>
        <div className='about-words'>
          <p>
          {'Hello! My name is Mason Swager, a web developer and former data analyst. I used to find development to be intimidating as it literally looked like magic. Someone would type some arcane words into the computer and the coolest things could happen. I was inspired to finally give it a shot by the amazing development team at my former workplace and now I’m not sure I’ll ever be able to stop learning more!'}
</p>
        </div>
      </div>
      <Forest/>
    </div>
  )
}
