//data
import herbalData from './projectData/herbalData.js'
import dojoData from './projectData/dojoData.js'
import newHorizonsData from './projectData/nhData.js'

const projects = [
    herbalData,
    dojoData,
    newHorizonsData,
]

export default projects