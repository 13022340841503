import './ProjectModal.css'

function Modal ({setSelectedProject, selectedProject,setModal}) {

    const handleClick=(elem)=>{
        if(elem.target.classList.contains('backdrop')){
            setSelectedProject(null)
            setModal(false)
        }
    }
    
    return(
        <div className="backdrop" onClick={handleClick}>
            <div className="modal-body projectmodal">
                <h2>{selectedProject.name}</h2>
                <img src={selectedProject.image} alt={`${selectedProject.name}`}/>
                <p>{selectedProject.desc}</p>
                {selectedProject.notes && <p className='projectmodal-notes'>{selectedProject.notes}</p>}
                <div className='projectmodal-buttons'>
                    <a 
                        className='btn' 
                        href={selectedProject.link} 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >View Project</a>
                    {selectedProject.github && <a 
                        className='btn' 
                        href={selectedProject.github} 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >Github</a>}
                </div>


            </div> 
        </div>
    )
}

export default Modal