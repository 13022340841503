//styles
import './Mountains2.css'
//images
import mountain1 from '../../assets/mountains/mountain1.svg'
import mountain2 from '../../assets/mountains/mountain2.svg'
import mountain3 from '../../assets/mountains/mountain3.svg'
import cloud1 from '../../assets/mountains/cloud1.svg'
import cloud2 from '../../assets/mountains/cloud2.svg'
import cloud3 from '../../assets/mountains/cloud3.svg'

export default function Mountains2() {
  return (
    <div id="mountains-clouds" className='background'>
      <img src={mountain1} id="mountain1" alt="mountain range"/>
      <img src={mountain2} id="mountain2" alt="mountain range"/>
      <img src={mountain3} id="backgroundm" alt="mountain range"/>
      <img src={cloud1} className="cloud" id="cloud1" alt="cloud"/>
      <img src={cloud2} className="cloud" id="cloud2" alt="cloud"/>
      <img src={cloud3} className="cloud" id="cloud3" alt="cloud"/>
    </div>
  )
}
