import { useState } from 'react'

//data
import projects from './projectData'
//styles
import './Projects.css'
//componets
import ProjectModal from './components/ProjectModal'

export default function Projects({refProjects, setModal}) {
    const [selectedProject, setSelectedProject]=useState(null)
    const [hoverProject, setHoverProject]=useState(null)

    const selectProject = (project)=>{
        setModal(true)
        setSelectedProject({
            name:project.name,
            link:project.link,
            github:project.github,
            notes:project.notes,
            image:project.image,
            desc:project.desc,
        })
    }

  return (
    <div className='projects container' ref={refProjects}>
        

{/* tv antennae */}
        <div className="tv__top">
            <div className="tv__antenna tv__antenna--left"></div>
            <div className="tv__antenna tv__antenna--right"></div>
            <div className="tv__antenna__base"></div>
        </div>

{/* hover tv */}
        <div className='tv'>
            <div className='screen'>
                {hoverProject && <img className="tv-screen-image" src={hoverProject.image} alt={hoverProject.name}/>}
            </div>
        </div>

        <h2>Projects</h2>


{/* tv buttons */}
        <div className='project-buttons'>
            {projects.map(project=>(
                <button 
                    key={project.name} 
                    onClick={()=>{selectProject(project)}}
                    onMouseEnter={()=>{setHoverProject({
                        image:project.image,
                        name:project.name,
                    })}}
                    onMouseLeave={()=>{setHoverProject(null)}}
                >
                    <div className={hoverProject && hoverProject.name===project.name ? 'light on' : 'light off'}></div>
                    <h3>{project.name}</h3>
                </button>
            ))}
        </div>


{/* Modal */}
        {selectedProject && <ProjectModal setSelectedProject={setSelectedProject} selectedProject={selectedProject} setModal={setModal}/>}
    </div>
  )
}
