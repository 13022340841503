//images
import newHorizonsImage from '../../../assets/projects/newHorizons-image.png'

const newHorizonsData = {
    name:"New Horizons",
    link:"https://new-horizons-app.herokuapp.com/",
    github:'https://github.com/Capstone-NewHorizons/new-horizons',
    notes:'due to changing policies with hosting service this project is undergoing updates',
    image:newHorizonsImage,
    desc:'New Horizons was the capstone project for my second web development bootcamp with The Fullstack Academy. I worked with three other students over two weeks to create a recommendation engine that would suggest activities to try based on completed activities by referencing a larger user database.\n\nNew Horizons is created with PostgreSQL, Node, Express, and React and deployed on Heroku. It also makes use of the react-bootstrap package along with plenty of css for styling.',
}

export default newHorizonsData